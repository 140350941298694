

















































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core';
import { camelCase } from 'lodash';
import DateRangeFilter from '@/components/date-range-filter.vue';
import { IDateRangeConfig } from '@/interfaces/date-range-config.interface';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { DateHelper } from '@/utils/helpers/date-helper';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import dayjs from '@/plugins/dayjs';
import VisitMadeService from '@/services/crm/visit-made.service';
import VisitMadeModel from '@/models/crm/visit-made.model';
import { VisitMadeStatusEnum } from '@/enums/crm/visit-made-status.enum';

type DataGridFilterConfig = {
  keyword: string | undefined;
  visitDate: (Date | undefined)[];
  status: VisitMadeStatusEnum | undefined;
};

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
  },
})
export default class CrmVisitsMade extends Vue {
  @inject(InjectionIdEnum.CrmVisitMadeService)
  private visitMadeService!: VisitMadeService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataVisita', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.visitsMade.grid.visit')}`,
        field: 'id',
        minWidth: 130,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.visitsMade.grid.date')}`,
        field: 'dataVisita',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.visitsMade.grid.situation')}`,
        field: 'situacao',
        flex: 0.5,
        valueGetter: (params: ValueGetterParams): string => {
          const statusKey = camelCase(VisitMadeStatusEnum[GridHelper.valueGetter(params)]);
          return `${this.$t(`crm.view.visitsMade.status.${statusKey}`)}`;
        },
      },
      {
        headerName: `${this.$t('crm.view.visitsMade.grid.tripReport')}`,
        field: 'relViagem',
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.visitsMade.grid.description')}`,
        field: 'descricao',
        autoHeight: true,
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
    visitDate: [undefined, undefined],
    status: undefined,
  };

  items: VisitMadeModel[] = [];

  selected: VisitMadeModel[] = [];

  predefinedVisitFilterRanges: IDateRangeConfig[] = this.getDateRanges();

  filterStatusOptions = [
    {
      code: VisitMadeStatusEnum.Evaluated,
      name: `${this.$t('crm.view.visitsMade.status.evaluated')}`,
    },
    {
      code: VisitMadeStatusEnum.UnderEvaluation,
      name: `${this.$t('crm.view.visitsMade.status.underEvaluation')}`,
    },
  ];

  async mounted(): Promise<void> {
    this.loadItems();
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: VisitMadeModel[]): void {
    this.selected = selected;
  }

  onExport(selected: VisitMadeModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Visitas Realizadas',
        fileName: VisitMadeService.generateVisitExportFilename(new Date()),
      });
    }
  }

  get filteredItems(): VisitMadeModel[] {
    let filteredItems = this.items;

    if (this.filters.keyword) {
      const columnsToSearch = ['id', 'situacao', 'relViagem', 'descricao'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    if (this.filters.status) {
      filteredItems = filteredItems.filter((item) => item.situacao === this.filters.status);
    }

    if (this.filters.visitDate && this.filters.visitDate[0]) {
      const start = dayjs(this.filters.visitDate[0]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataVisita).isAfter(start) || dayjs(item.dataVisita).isSame(start),
      );
    }

    if (this.filters.visitDate && this.filters.visitDate[1]) {
      const end = dayjs(this.filters.visitDate[1]);
      filteredItems = filteredItems.filter(
        (item) => dayjs(item.dataVisita).isBefore(end) || dayjs(item.dataVisita).isSame(end),
      );
    }

    return filteredItems;
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        case 'visitDate':
          if (this.filters[key] && (this.filters[key][0] || this.filters[key][1])) {
            active += 1;
          }
          break;
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  private getDateRanges(): IDateRangeConfig[] {
    return [
      {
        name: `${this.$t('global.currentMonth')}`,
        ...DateHelper.getCurrentMonthPeriod(),
      },
      {
        name: `${this.$t('global.lastMonth')}`,
        ...DateHelper.getLastMonthsPeriod(1),
      },
      {
        name: `${this.$t('global.lastThreeMonths')}`,
        ...DateHelper.getLastMonthsPeriod(3),
      },
      {
        name: `${this.$t('global.lastSixMonths')}`,
        ...DateHelper.getLastMonthsPeriod(6),
      },
      {
        name: `${this.$t('global.lastYear')}`,
        ...DateHelper.getLastYearsPeriod(1),
      },
      {
        name: `${this.$t('global.currentYear')}`,
        ...DateHelper.getCurrentYearPeriod(),
      },
    ];
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = await this.visitMadeService.getVisits(this.getClientIdFromRoute());
    this.gridSettings.loading = false;
  }

  private getClientIdFromRoute(): string {
    if (!this.activeClient) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }
}
